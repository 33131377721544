export const siteInfo = {
  name: "BuyerZone",
  navColor: "#FFD670",
  secondary: "#007057",
  primary_light_bg: "#E9EFF0",
  asideColor: "#fff",
  payable: 0.6,
  sitekey: "bzadactoklab",
  minimum_price: 1000,
  url: "https://buyerzonebd.com",
  cdnUrl: "https://cdn.buyerzonebd.com",
  phone: "01841001090",
  address: "Sector 6 , Road 11, House 19, Uttara Dhaka",
  email: "info@buyerzonebd.com",
  minimum_quantity: 3,
  error: "#C4161C",
  tableHeader: "#E9F0EE",
  by_air: {
    a: 630,
    b: 750,
  },
};
